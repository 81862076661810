.container {
  padding: 60px;
}

.flower {
  position: relative;
  transform: translate3d(0, 0, 0);
}

.flower .flower__center {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: var(--chakra-colors-yellow-medium);
  position: relative;
  z-index: 10;
  will-change: transform;
}

.flower .flower__leaves {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.flower .flower__leaf {
  transform-origin: center bottom;
  position: absolute;
  will-change: transform;
}

.flower .flower__leaf .flower__leaf-inner {
  transform-origin: center bottom;
  transform: scale(0);
  will-change: transform;
}
